import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import ContactMe from "../components/ContactMe";
import "../styles/ContactPage.css";
import { Message } from "@mui/icons-material";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import List from "@mui/material/List";

const ContactPage = () => {
  const [message, setMessage] = React.useState("");

  const style = {
    py: 0,
    width: "100%",
    maxWidth: "100%"
  };

  const style2 = {
    p: 1,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height:'auto'
  };

  const handleSubmit = (event) => {
    let subjectLine = "Let's Connect!";
    event.preventDefault();

    // const { message } = event.target.elements;
    alert(message);
    const mailtoLink = `mailto:klwegner@gmail.com?subject=${subjectLine}&body=${message.value}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <main>
        <Box
          sx={{
            bgcolor: "#E4DCCF",
            py: 2
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#576F72"
              //gutterBottom
            >
              Let's Talk
            </Typography>
           
          </Container>
        </Box>
        <Box sx={{ py: 2, mx: 1 }}>

        <Typography variant="h5" align="center" color="#576F72" paragraph>
              Feel inspired to reach out?<br></br> Please do so below.
            </Typography>
            </Box>

        <Container sx={{ py: 0, maxWidth: "100vw" }}>
          <Box sx={style2}>
            <List sx={style}>
              <form onSubmit={handleSubmit}>
                <div className="nameEmail">
                  <TextField
                    label="Your Name"
                    id="outlined-start-adornment"
                    sx={{
                      m: 1,
                      width: {
                        xs: "80vw", // for screens smaller than 600px
                        md: "80%", // for screens 600px and larger
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> <i>Name</i></InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label="Your Email"
                    id="outlined-start-adornment"
                    sx={{
                      m: 1,
               width: {
                        xs: "80vw", // for screens smaller than 600px
                        md: "80%", // for screens 600px and larger
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"><i>Email</i></InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="nameEmail">
                  <TextField
                    id="outlined-textarea"
                    label="message"
                    placeholder="Message"
                    multiline
                    rows="5"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    sx={{
                      m: 1,
width: {
                        xs: "80vw", // for screens smaller than 600px
                        md: "80%", // for screens 600px and larger
                      },                    }}
                  />
                </div>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    m: 2,
                    p: 0                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    type="submit"
                    name="submit"
                    // sx={{p:'5px 15px'}}
                  >
                    Send
                  </Button>

                  <Button
                    variant="outlined"
                    // startIcon={<DeleteIcon />}
                    type="reset"
                    name="reset"
                    onClick={() => setMessage("")}
                  >
                    Clear Form
                  </Button>
                </Stack>
              </form>
            </List>
          </Box>
        </Container>
      </main>
    </>
  );
};
export default ContactPage;
