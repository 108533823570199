import "../styles/AboutMe.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import resume from "../assets/2025resume.pdf";

const AboutMe = () => {
  const style2 = {
    p: 2,
    display: "flex",
    justifyContent: "center",
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%"
  };

  return (
    <>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "#E4DCCF",
            py: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#576F72"
            >
              About Me
            </Typography>
          </Container>
        </Box>

        <Box sx={style2}>
          <Typography variant="h6" align="left" color="#576F72" paragraph>
            I am Kristen Wegner,
          and I believe in the great power that technology holds to
            make our lives richer, healthier, and happier.
            <br />
            <br />
            Working as a web developer in higher education, I see how quality
            web development and integrations make clear the way to focus on the bigger things in life:
            Learning. Exploring. Togetherness.
            <br></br>
            <br></br>
            Check out my resume{" "}
            <a href={resume} download>
              {" "}
              here{" "}
            </a>
            .<br></br>
            <br></br>
          </Typography>
        </Box>
      </main>
    </>
  );
};

export default AboutMe;
