import * as React from "react";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const style = {
  // py: 0,
  // width: "100%",
  py: 2,
  width: "90%",
  marginLeft: "5%",
  marginRight: "5%",
  backgroundColor: "#F0EBE3", //main color
};

const style2 = {
  p: 1,
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const indentation = {
  marginLeft: "5vw",
};

const ExperiencePage = () => {
  return (
    <>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "#E4DCCF",
            py: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#576F72"
            >
              Experience
            </Typography>
          </Container>
        </Box>

        <Box sx={style}>
          <Typography
            variant="h6"
            align="left"
            color="#576F72"
            paragraph
            mb="0"
          >
            I've been enveloped in the world of web development and integrations
            since 2022.
            <br></br> My position calls for great flexibility and an ability to
            efficiently learn and troubleshoot new languages and frameworks.
          </Typography>
        </Box>

        <Container sx={{ py: 0, maxWidth: "100vw" }}>
          <Box sx={style2}>
            <List sx={style}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      Application Developer/Web Analyst, Hillsborough Community
                      College
                    </Typography>
                  }
                />
              </ListItem>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                <ul> 12/2022 - Present</ul>
              </Typography>
              <Typography variant="h6">
                <ul>
                  Over my career thus far, I've worked with Angular, Apex, REST
                  APIs, Azure Services, SharePoint, Salesforce, and Workday
                  Extend to:
                </ul>
                <Box sx={indentation}>
                  <ul>
                    <li>
                      Collaborate with team to build new UI for Hillsborough
                      Community College's student and staff online portal and to
                      create and test API connectors to extend its functionality
                    </li>
                    <li>
                      Create new custom Salesforce objects (sObjects), flows,
                      and object relationships to extend our Salesforce
                      environment to accept and process new types of academic
                      program applications
                    </li>
                    <li>
                      Create custom model components to hold vital information
                      related to Workday delivered objects for state reporting
                      purposes
                    </li>
                  </ul>
                </Box>
              </Typography>
            </List>
          </Box>
        </Container>
      </main>
    </>
  );
};

export default ExperiencePage;
