import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const style = {
  py: 2,
  width: "90%",
  marginLeft: "5%",
  marginRight: "5%",
  backgroundColor: "#F0EBE3", //main color


};

const style2 = {
  p: 2,
  //backgroundColor: "#E4DCCF", //peachish tan
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: {
    xs: "20vw", // for screens smaller than 600px
    sm: "10vw", // for screens 600px and larger
  },
};
const indentation = {
  marginLeft: "5vw",
};

const EducationPage = () => {
  return (
    <>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "#E4DCCF",
            pt: 2,
            pb: 2,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#576F72"
              //gutterBottom
            >
              Education
            </Typography>
          </Container>
        </Box>

        <Box sx={style}>
                  <Typography
            variant="h6"
            align="left"
            color="#576F72"
            paragraph
            mb="0"
          >
            Like my experience, my education is broad and varied, with a focus
            on problem solving and communications.
            <br></br>
            To further my expertise in CS, I am currently enrolled in the
            University of Colorado Boulder's Masters in Computer Science
            program.
          </Typography>
        </Box>

        <Container sx={{ py: 2, maxWidth: "100vw" }}>
          <Box sx={style2}>
            <List sx={style}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      M.S. in Computer Science, University of Colorado Boulder
                    </Typography>
                  }
                />
              </ListItem>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                <ul>2024 - Present</ul>
              </Typography>

              <Typography variant="h6">
                <Box sx={indentation}>
                  <ul>
                    <li>Algorithms</li>
                    <li>Generative AI</li>
                    <li>Government Policy and Regulations</li>
                  </ul>
                </Box>
              </Typography>
              <Divider variant="middle" component="li" />

              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      Computer Science, Hillsborough Community College
                    </Typography>
                  }
                />
              </ListItem>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                <ul>2023 - 2024</ul>
              </Typography>

              <Typography variant="h6">
                <Box sx={indentation}>
                  <ul>
                    <li>Programming Logic</li>
                    <li>Java</li>
                    <li>AI</li>
                    <li>Python</li>
                    <li>Client-Side Web Development</li>
                  </ul>
                </Box>
              </Typography>

              <Divider variant="middle" component="li" />

              {/* <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h4">EdX Certificate</Typography>
                  }
                />
              </ListItem>
              <Typography variant="h5" sx={{ fontStyle: "italic" }}>
                <ul>Completed Jul. 2023</ul>
              </Typography>

              <Typography variant="h5">
                <ul> Introduction to Python</ul>
              </Typography>

              <Divider variant="inset" component="li" /> */}

              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      Full-Stack Web Development Bootcamp, Ironhack
                    </Typography>
                  }
                />
              </ListItem>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                <ul>Completed Aug. 2022</ul>
              </Typography>

              <Typography variant="h6">
                <Box sx={indentation}>
                  <ul>
                    <li>React</li>
                    <li>Node</li>
                    <li>Express</li>
                    <li>MongoDB</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </Box>
              </Typography>

              <Divider variant="middle" component="li" />
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">
                      B.A. in English Literature, University of South Florida
                    </Typography>
                  }
                />
              </ListItem>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                <ul>Completed Dec. 2016</ul>
              </Typography>

              {/* <Divider variant="middle" component="li" /> */}
            </List>
          </Box>
        </Container>
      </main>
    </>
  );
};

export default EducationPage;
